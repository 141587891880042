section.poster {
  display: grid;
  grid-template-areas: "poster";
  > * {
	  grid-area: hero;
	}
  height: 90vh;
  padding: 0;
  overflow: hidden;
}
.poster-background {
  object-fit: cover;
  height: 90vh;
  width: 100vw;
}
.poster-inner {
	@include xy-cell;
	max-width: rem-calc(1400);
	margin: 0 auto;
	align-items: stretch;
	position: relative;
	z-index: 2;
}

.poster-content {
	@include xy-cell(12);
	padding: rem-calc(30);
	border-radius: $global-radius;
	-webkit-box-shadow: 0 0 15px 0 rgba($black,.5);
	box-shadow: 0 0 15px 0 rgba($black,.5);
	position: absolute;
	color: $white;
	h2 {
		margin-top: 0;
	}
	h1,h2,h3,h4,h5,h6,blockquote {
		color: $tint-beige;
	}
	.button {
		background: $tint-beige;
		&:hover {
			background: $white;
		}
	}
	@include breakpoint(large) {
		@include xy-cell(5);
		margin: rem-calc(30 0)
	}
}
.poster-content.green {
	background: $dark-green;
	.button {
		color: $darker-green;
	}
}
.poster-content.primaryColor {
	background: $primary-color;
	.button {
		color: $primary-color;
	}
}
.poster-content.transparentDark {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.poster-content.transparentLight {
	-webkit-box-shadow: none;
	box-shadow: none;
	color: $black;
	h1,h2,h3,h4,h5,h6 {
		color: $primary-dark;
	}
	.button {
		background: $primary-color;
		&:hover {
			background: $primary-light;
		}
	}
}
.poster-content.center {
	@include xy-cell(8);
	top: 50%;
	transform: translateY(-50%);
}
.poster-content.topLeft {
	left: 0;
	top: 30px;	
}
.poster-content.middleLeft {
	left: 0;
	top: 30px;
	@include breakpoint(large) {
		top: 50%;
		transform: translateY(-50%);			
	}
}
.poster-content.middleRight {
	right: 0;
	top: 30px;
	@include breakpoint(large) {
		top: 50%;
		transform: translateY(-50%);			
	}
}

.poster-content.topRight {
	right: 0;
	top: 30px;	
}
.poster-content.bottomRight {
	right: 0;
	bottom: 30px;	
}
.poster-content.bottomLeft {
	left: 0;
	bottom: 30px;
}

.ctaBlock + .poster {
	padding-top: 0 !important;
}
.ctaBlock + .poster .poster-content {
	margin-top: rem-calc(60);
}