
.featured-page {
	@include xy-grid;
	margin-bottom: rem-calc(30);
	border-radius: $global-radius;
	overflow: hidden;
	border: 1px solid rgba($dark-gray,.5);
}
.display-rows .featured-image {
	@include xy-cell(4);
	display: flex;
	margin-left: 0;
	margin-right: 1.25rem;
	@include flex-align(right, middle);
}
.display-rows .featured-content {
	@include xy-cell(8);
	@include flex-align-self(middle);
	h2 {
		margin-top: 0;
		margin-bottom: rem-calc(15);
	}
	padding: rem-calc(15);
	margin: 0;
}
.featured-image a {
	@include flex-align-self(stretch);
}
.featured-image img {
	object-fit:cover;
	height: 100%;
}

.display-cards .inner {
	@include xy-grid;
}
.up2 .inner {
	@include breakpoint(medium) {
		@include xy-grid-layout(2, '.featured-page');		
	}
}
.up3 .inner {
	@include breakpoint(medium) {
		@include xy-grid-layout(2, '.featured-page');		
	}
	@include breakpoint(large) {
		@include xy-grid-layout(3, '.featured-page');	
	}
}
.up4 .inner {
	@include breakpoint(medium) {
	@include xy-grid-layout(2, '.featured-page');		
	}
	@include breakpoint(large) {
		@include xy-grid-layout(4, '.featured-page');
	}
}
.display-cards .featured-page {
	@include flex-direction(column);
}
.display-cards .featured-content {
	padding: rem-calc(30);
	width: 100%;
	@include flex-align-self(stretch);

	h2 {
		margin-top: 0;
		margin-bottom: rem-calc(15);
	}
}

