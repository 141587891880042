/* ----------------------------------------------------------------------------------------------------

	TYPOGRAPHY

---------------------------------------------------------------------------------------------------- */

body {
	font-size: rem-calc(16);
	color: $text-gray;
	-moz-hyphens: none;
	-ms-hyphens: none;
	-webkit-hyphens: none;
	hyphens: none;
	@include breakpoint(large) {
		font-size: rem-calc(20);
	}
}

p,
ul {
	@include breakpoint(medium) {
		font-size: rem-calc(18);
	}
	@include breakpoint(large) {
		font-size: rem-calc(20);
	}
}
p.lead {
	font-size: rem-calc(22);
	@include breakpoint(medium) {
		font-size: rem-calc(26);
	}
}
.button {
	font-weight: bold;
	@include breakpoint(medium) {
		font-size: rem-calc(16);
	}
	@include breakpoint(large) {
		font-size: rem-calc(18);
	}
}
.eyebrow {
	font-family: $body-font-family;
	font-size: rem-calc(16);
	text-transform: uppercase;
	letter-spacing: rem-calc(3);
	margin-bottom: 0;
	color: $primary-color;

}
p,
h1,
h2,
h3,
h4,
h5,
h6,
.main-content ul,
blockquote,
cite,
.search-result,
.searchbox,
table {
	max-width: rem-calc(1000);
	margin-left:auto;
	margin-right:auto;
	margin-bottom: 1em;
}
.main-content {
	max-width: rem-calc(1400);
	margin: 0 auto;
	padding: rem-calc(0 40);
}
.main-content > *:first-child {
	padding-top: rem-calc(40);
}
.main-content > *:last-child {
	padding-bottom: rem-calc(40);
}
.main-content > h1 {
	margin-top: rem-calc(15);
	margin-bottom: rem-calc(30);
	@include breakpoint(medium) {
		margin-top: rem-calc(30);	
	}
	color: $maroon;
}

h2 {
	margin-top: rem-calc(0);
	// @include breakpoint(medium) {
	// 	margin-top: rem-calc(15);		
	// }
	// @include breakpoint(large) {
	// 	margin-top: rem-calc(30);
	// }
}
.background-beigeLight+h2,
.background-beigeLight+p,
.background-beigeDark+h2,
.background-beigeDark+p,
.poster+h2,
.poster+p {
	margin-top: rem-calc(30);
}
.breadcrumbs li {
	font-size: rem-calc(16);
}

figcaption,
figcaption p,
.caption {
	text-align: center;
	color: $dark-gray;
	font-size: rem-calc(18);
}
table + .caption {
	margin-top: rem-calc(-15);
	margin-bottom: rem-calc(30);
}
p.meta { 
	font-size: 80%; 
}
blockquote {
	font-family: $header-font-family;
	border: none;
	margin: 0;
	padding: 0;
	font-size: 125%;
	line-height: 1.2;
	margin-bottom: rem-calc(15);
	color: $primary-dark;
}
ul:not([class]),
ul.two-column {
	list-style: none;
	margin: 0 auto 1em;
	max-width: rem-calc(1000);

	li { 
		line-height: 1.4; 
		margin-bottom: rem-calc(7);
		padding-left: rem-calc(30);
	}
	li::before {
		font-family: 'Font Awesome 5 Free';
		content: "\f06c";
		font-weight: 900;
		color: $dark-green;
		margin-right: rem-calc(10);
		margin-left: rem-calc(-30);
	}
}
ul.centered {
	margin: 0 auto;
}

ul.two-column {
	@include breakpoint(large) {
		column-count: 2;
		column-gap: rem-calc(30);
		li {
			break-inside: avoid;
		}	
	}
}
