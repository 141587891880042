// IMAGE GALLERY
.image-gallery {
	max-width: rem-calc(1440);
	margin: 0 auto;
	@include xy-grid;
}
.gallery-image {
	@include xy-cell(6);
	margin: rem-calc(15);
	overflow:hidden;
	border-radius: $global-radius;
	@include breakpoint(medium) {
		@include xy-cell(4);
	}
	@include breakpoint(large) {
		@include xy-cell(3);
	}

	img {
		transition: all .2s ease-in-out;
		overflow: hidden;
		&:hover {
			transform: scale(1.1);
		}
	}
}
