@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
@include foundation-table;

// Basic components
// @include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
// @include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import 'partials/subnav';
@import 'partials/hero';
@import 'partials/ctaBlock';
@import 'partials/poster';
@import 'partials/imageCopy';
@import 'partials/typography';
@import 'partials/faq';
@import 'partials/featuredDownload';
@import 'partials/imageGallery';
@import 'partials/footer';
@import 'partials/events';
@import 'partials/floorplans';
@import 'partials/featuredPages';
@import 'partials/quote';


/* ----------------------------------------------------------------------------------------------------

	UTILITY CLASSES

---------------------------------------------------------------------------------------------------- */

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
.grow.small:hover { transform: scale(1.05); }

.gen-container{
	max-width: 87.5rem;
	margin-left: auto;
	margin-right: auto;
}

.gen-space1{
	margin-top: 1em;
	margin-bottom: 1em;
}

.topspace1{
	margin-top: 1em;
}

.btm-space1{
	margin-bottom: 1em;
}

.gen-pad1{
	padding-top: 1em;
	padding-bottom: 1em;
}
.gen-pad2{
	padding-top: 2.5em;
	padding-bottom: 2.5em;
}





/* ----------------------------------------------------------------------------------------------------

	MASTHEAD / NAVIGATION

---------------------------------------------------------------------------------------------------- */

.announcement-banner {
	width: 100%;
	background: $primary-color;
	color: $white;
	padding: rem-calc(4);
	p {
		margin-bottom: 0;
	}
	a {
		color: $white;
		&:hover {
			color: scale-color($warning-color, $lightness: 50%);;
		}
	}
}
.phone-menu {
	padding: rem-calc(7) 0;	
}
.phone-dropdown {
	.menu li {
		text-align:left;
	}
}
.utility-container{
	
	background-color: $primary-dark;
}
.menu.utility-nav li a {
	color: $white;
	&:hover {
		color: $beige;
	}
}
.utility-nav {
	color: #fff;
	font-size: rem-calc(20);
  
	a {
	  margin: 0 0.5rem;
	  text-decoration: none;
	}
  
	.dropdown {
	  position: relative;
  
	  .dropdown-toggle {
		cursor: pointer;
	  }
  
	  .dropdown-menu {
		display: none;
		position: absolute;
		left: 0;
		background-color: $primary-dark;
		color: #fff;
		width: 100%;
		z-index: 999;
  
		a {
		  display: block;
		  padding: 0.5rem;
		  color: #ffffff;
		}
	  }
	}
  
	i.fas {
	  margin: 0 0.25rem;
	}
  
	@media screen and (max-width: 640px) { // Adjust the breakpoint as needed
	  text-align: center;
  
	  .cell {
		margin-bottom: 0.5rem;
	  }
	}
  }
  
  

.masthead {
	z-index:10;
	background: $white;
	max-width: rem-calc(1400);
	padding: rem-calc(20);
	margin: 0 auto -8px;
	
	@include xy-grid;
}

.logo {
	@include xy-cell(8);
	img {
		max-width: rem-calc(180);
		min-width: rem-aclc(120);
		display: block;
		transition: all .2s;
		&:hover {
			transform: scale(1.125);
		}
	}
	@include breakpoint(large) {
		@include xy-cell-offset(0);
		@include xy-cell(2);
		margin: 0 auto;
		img {
			margin: 0;
		}
	}

}
.mobile-nav {
	@include hide-for(large);
	@include xy-cell(4);
	@include flex-align-self(middle);
	text-align: right;
	display: inline;
	.button {
		margin-bottom: 0;
	}
}
#nav-mobile {
	background: $white;
	.menu {
		padding-top: rem-calc(60);
		background: $white;
	}
	a {
		color: $text-gray;
		border-color: $white;
		padding: rem-calc(15);
		font-size: rem-calc(20);
		background: transparent;
		text-align:center;
		border-bottom: 1px solid $beige;	
		&:hover {
			background: $tint-beige;
			color: $primary-dark;
		}
	}
	.close-button {
		background: transparent;
		color: $primary-color;
	}
}
#nav-mobile .menu .active>a {
	border-bottom: none;
	background: rgba($white,.1);
}
.drilldown .js-drilldown-back>a::before {
	border-color: transparent $primary-color transparent transparent;
}
.navigation {
	// position:relative;
	z-index: 10;
	@include show-for(large);
	@include breakpoint(medium) {
		@include xy-cell(auto);
		@include flex-align-self(middle);
		margin-right: 0;
		a:hover {
			color: $primary-color;
		}
	}
}
.menu.main-nav {
	margin-right: 0;
	a:hover {
		color: $primary-color;
	}	
}
.menu .nav-utility{
	background-color: $primary-dark;
}
.menu.nav-utility a {
	font-weight: normal;
	font-size: rem-calc(20);
	color: $text-gray;
	&:hover {
		color: $dark-blue;
	}
}
.masthead .menu .active > a {
	background: transparent;
	color: $primary-dark;
	font-weight: bold;
	&:hover {
		color: $primary-color;
	}
}
.masthead .menu .active>a.button {
	border-bottom:1px solid $primary-color;
	color: $primary-color;
}
.menu li {
	text-align: right;
	@include breakpoint(medium) {
	text-align: center;
	align-self: center;
	}
	@include breakpoint(large) {
		max-width: 20%;
	}
	@include breakpoint(xxlarge) {
		max-width: 50%;
	}
	a {
		color: $dark-blue;
		font-weight: bold;
		font-size:1.2rem;
		padding: rem-calc(12);
		margin: 0;
	}
}
.is-drilldown {
	max-width: 100% !important;
}
.is-dropdown-submenu {
	position:absolute;
	z-index: 10;
	margin-right: 0;
	width: rem-calc(300);
	background: $tint-beige;
	border-color: $beige;
	-webkit-box-shadow: 0 0 10px 0px rgba($black,.2);
	box-shadow: 0 0 10px 0px rgba($black,.2);
	border-radius: rem-calc(5);
	li {
		max-width: 100%;
	}
	li a {
		text-align: center;
		margin: 0;
		width: 100%;
		padding: rem-calc(0 16);
	}
}
.menu .is-dropdown-submenu .active>a,
.menu .is-dropdown-submenu .active>a:hover {
	border-bottom: none;
	background: $primary-dark;
	color: $white;
}
.footer .menu .active>a {
	color: $light-blue;
	background: none;
}
.menu .is-dropdown-submenu a:hover {
background: $white;
color: $primary-color;
}
.dropdown.menu>li>a {
padding: rem-calc(12);
@include breakpoint(xxlarge) {
	padding: rem-calc(12 16);
}
}

a {
	color: $primary-color;
}
a p {
	color: $text-gray;
}
a:hover {
	color: $secondary-color;
}
.button:hover {
	background: $secondary-color;
}

p.button {
	margin-bottom: 0;
}
.button.centered {
	@include flex;
	@include flex-align(center);
	width: auto;
}
.hollow.button {
	color: $primary-dark;
	border-color: $primary-dark;
}
.menu.main-nav .hollow.button:hover,
.hollow.button:hover {
	background: $primary-dark;
	color: $white;
	border-color: $primary-dark;
}
.nav-prev,
.nav-next {
	font-size: rem-calc(30);
	color: $medium-gray;
}
.nav-prev {
	float: left;
}
.nav-next {
	float: right;
}

/* ----------------------------------------------------------------------------------------------------

	LAYOUT

---------------------------------------------------------------------------------------------------- */
body {
	background: $white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.main {
	background: $white;
	flex-grow: 1;
}
img {
	max-width: auto;
	width: 100%;
}
section {
	padding: rem-calc(30);
	clear: both;
}
.space-top {
	margin-top: rem-calc(30);
}
.collapse-bottom {
	padding-bottom: 0;
}
.collapse-top {
	padding-top: 0;
}
hr.divider {
	border-color: $beige;
	border-bottom-width: 4px;
	margin: rem-calc(30) auto;
	max-width: rem-calc(1000);
	@include breakpoint(large) {
		margin: rem-calc(60) auto;
	}
}
hr.divider.space {
	border-color: transparent;
}

.page-intro {
	margin-top: rem-calc(60);
	@include breakpoint(large) {
		margin-top: rem-calc(90);
	}
}

figure.rounded img {
	border-radius: $global-radius;
}

.full-bleed {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}
.tint {
	background: $beige;
	padding: rem-calc(15);
    box-shadow: inset 0 -16px 8px -8px rgba($primary-color,.2), inset 0 16px 8px -8px rgba($primary-color,.2);
    @include breakpoint(medium) {
		padding: rem-calc(60);    	
    }
}

.cards {
	@include flex-align(center, stretch);
}
.cards .cell {
	max-width: rem-calc(1000);
	@include flex;
	@include flex-align-self(stretch);
	padding: 0;
}

.card img {
	width: 100%;
	max-width: auto;
	@include flex-align-self(stretch);
}
.card-section {
	@include breakpoint(large) {
		padding: rem-calc(30);
	}
	@include breakpoint(xlarge) {
		padding: rem-calc(60);
	}
}
.card-section h3 {
	font-size: rem-calc(28);
	@include breakpoint(medium) {
		font-size: rem-calc(32);
	}
}
.card-section .button {
	margin-bottom: 0;
}
.display-cards {
	@include xy-grid;
	@include xy-grid-layout(3, '.cell')
}
.card-section.promoBox {
	text-align:center;
	background: $primary-color;
	color: $white;
	@include breakpoint(xlarge) {
		padding: rem-calc(30 60);
	}
	h1,h2,h3,h4,h5,h6,a {
		color: $white;
	}
	a {
		color: white; 
		text-decoration:underline;
		&:hover {
			color: rgba($white,.8);
		}
	}
	a.button {
		background: $white;
		color: $primary-dark;
		text-decoration: none;
		margin: rem-calc(0 15);
		&:hover {
			background: rgba($white,.7);
		}
	}
}

.display-blocks {

	.cell {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.card {
		border: none;
		@include xy-grid;
		margin: 0;

	}
	.card-image {
		@include xy-cell(5);
		border-radius: $global-radius;
		margin: 0;
		img {
			object-fit: cover;
		}
	
	}
	.card-section {
		@include xy-cell(12);
		@include flex-align-self(middle);
		@include breakpoint(medium) {
			@include xy-cell(6);	
		}
	}
	.even .card-image {
		@include breakpoint(medium) {
			@include flex-order(2);
		}
	}
	.even .card-section {
		@include breakpoint(medium) {
			@include flex-order(1);
		}
	}
}
.display-blocks.last {
	margin-bottom: rem-calc(-40);
}
.community-section {
  margin-top: rem-calc(60);
}

figure a {
	display: block;
}

.img-right {
	float: right;
	margin-left: rem-calc(30);
	margin-bottom: rem-calc(30);
}
.img-left {
	float: left;
	margin-right: rem-calc(30);
	margin-bottom: rem-calc(30);
}
.img-center {
	margin-bottom: rem-calc(30);
	margin-left: auto;
	margin-right: auto;
}
.img-medium {
	max-width: 50%;
}
.img-small {
	max-width: 35%;
}
.img-icon {
	max-width: rem-calc(150);
}
//COPY WITH IMAGE
.accordion{
	padding: 1em;
}

.accordion .accordion-item{
	margin-bottom: 1em;
	border-radius: 0;
	
}

.accordion-item:first-child>:first-child {
	border-radius: 0;

}
.accordion-item:last-child>:last-child {
	border-radius: 0;

}

:last-child:not(.is-active) > .accordion-title {
    border-bottom: none;
    border-radius: 0px;
}


//COPY WITH IMAGE
.copy-img{
	background-color: $beige;
	padding-top: 2em;
	padding-bottom: 2em;
}
.copy-img-white{
	background-color: $white;
	padding-top: 2em;
	padding-bottom: 2em;
}
.copy-txt{
	margin-top: auto;
	margin-bottom: auto;
	
}

.side-img{
	border-radius: $global-radius;
}
.copy-txt h2{
	margin-bottom: 1em;
	color: $primary-dark;
}

.copy-txt a{
	background-color: $primary-light;
	border-radius: .5em;
}

//GALLERY
.sv-gallery{
	margin-top: 2em;
	margin-bottom: 2em;
	
}

.sv-gallery h2{
	color: $primary-dark;
	margin-bottom: 1em;
}

.sv-gallery .gallery-img{
	margin-bottom: 1.5em;
}

// COLUMNS

.columns {
	@include xy-grid;
	max-width: rem-calc(1030);
	margin: rem-calc(30) auto 0;
}
.column {
	@include breakpoint(medium) {
		@include xy-cell(6)
	}
}

.showcase{
	background: url('/assets/uploads/images/hero-sv-art.jpg') no-repeat -100px 0;
	min-height: 600px;
	color:$white;
	@include breakpoint(medium down){
		min-height: 400px;
	}
	@include breakpoint(small down){
		min-height: 400px;
	}
  }
  
  .showcase .showcase-box{
	padding: 2em;
	margin-top: rem-calc(120);
	background: $light-green;
	color:$white;
	border-radius: $global-radius;
	max-width: 60%;
	box-shadow: 0px 10px 29px 0px rgba(0,0,0,0.6);
	@include breakpoint(medium down){
		margin-top: rem-calc(40);
		max-width: 70%;
	}
	@include breakpoint(small down){
		margin-top: rem-calc(30);
		max-width: 100%;
	}
  }
  

.showcase-box h2{
	color: $white;
	margin-top: 0;
	
  }



// BIOS

.bio {
	@include xy-grid;
	margin-bottom: rem-calc(30);
}
.bio-image {
	@include xy-cell(3);
	@include breakpoint(large) {		
		@include xy-cell(2);
	}
}
.bio-content {
	@include xy-cell(auto);
}
.bio-name {
	margin-bottom: 0;
}
.bio-title {
	font-style: italic; 
	text-transform: uppercase;
	font-size: 80%;
}
.bio hr { 
	margin-top: 0;
	margin-bottom: 0;
}

// SEARCH
.search .searchbox {
	margin-bottom: rem-calc(60);
}
.search-result {
	margin-bottom: rem-calc(30);
	border-bottom: 4px solid $beige;
	padding-bottom: rem-calc(15);
}
.search-icon {
	color: $white;
	&:hover {
		color: $beige;
	}
}
.reveal.search-dropdown {
	background: $beige;
	border: none;
	width: 95%;
	max-width: none;
}
.reveal .searchbox {
	max-width: none;
}
// NEWS
.news-listing {
	margin-top: rem-calc(30);
	max-width: rem-calc(1000);
	margin: 0 auto;
}
.news-blurb {
	@include xy-grid;
	margin-bottom: rem-calc(30);
	border-bottom: 4px solid $beige;
	padding-bottom: rem-calc(15);
}
h2.news-headline {
	margin-top: 0;
	margin-bottom: rem-calc(15); 
}
.news-source {
	margin-top: rem-calc(-8);
	font-size: rem-calc(18);
	.source {
		font-size: rem-calc(16);
		text-transform: uppercase;
		letter-spacing: rem-calc(1);
		color: rgba($primary-color,.8);
	}
}
.news-image {
	@include xy-cell(3);
	figure {
		border-radius: $global-radius;
		overflow: hidden;
	}
}
.news-content {
	@include xy-cell(9);
}
.prev-next {
	clear:both;
	border-top: 4px solid $primary-color;
	padding-top: rem-calc(30);
	margin-top: rem-calc(60);
	margin-bottom: rem-calc(60);
	text-align: center;
}


// VIDEO
.video-link {
	display: block;
	position: relative;
	&:hover {
		.playbutton {
		color: rgba($white,1);
		}
	}

}
.playbutton {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-45%);
	-ms-transform: translate(-50%,-45%);
	transform: translate(-50%,-45%);
	text-shadow: 0 0 .9375rem rgba(10,10,10,.8);
	color: rgba($white,.5);
	font-size: rem-calc(32);
	@include breakpoint(medium) {
		font-size: rem-calc(72);
	}
	@include breakpoint(large) {
		font-size: rem-calc(100);
	}
}
.yt-thumb {
	aspect-ratio: 16/9;
	object-fit: cover;
}
figure.video {
	margin: 0 auto rem-calc(30);
}
figure.video+figcaption {
	margin: rem-calc(-15 0 30);
}
.videos {
	@include xy-grid;
	@include flex-align(center,top);
}
.up-2 {
	@include grid-layout(2,'.cell');
}
.up-3 {
	@include grid-layout(2,'.cell');
	@include breakpoint(medium) {
		@include grid-layout(3,'.cell')
	}
}
.up-4 {
	@include grid-layout(2,'.cell');
	@include breakpoint(medium) {
		@include grid-layout(4,'.cell')
	}
}
.cell {
	padding: 0;
	@include breakpoint(medium) {
		padding: rem-calc(0 15);
	}
}
.up-1 .cell  {
	padding: 0;
}



// BREAKPOINT
.breakpoint {
    background: rgba($white,.8);
    position: fixed;
    bottom: 0;
    left:0;
    padding: 5px;
    p {
        margin:0;
    }
}


// ENQUIRE FORMS
.activedemand:not(.skip-activedemand-css) .container-fluid {
	padding-left: 0;
	padding-right: 0;
}
.activedemand .column {
	margin-left: 0;
	margin-right: 0;
}

.activedemand-button-container .activedemand-button {
	background: $primary-color !important;
	color: $white !important;
	border-radius: 0 !important;
	font-size: rem-calc(18) !important;
	font-weight: bold !important;
	&:hover {
		background: $secondary-color !important;
	}
}


#cbd3ee35f2 .cta-content-wrapper p {
	font-size: rem-calc(22);
}
.activedemand-wrapper {
	max-width: rem-calc(1000);
	margin: 0 auto;
	float: none !important;
	input {
		margin-bottom: rem-calc(15) !important;
	}
}

.activedemand-wrapper {
	padding: 0 !important;
}