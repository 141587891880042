.events-blurb {
	@include xy-grid;
	margin-bottom: rem-calc(60);
}
.events-image {
	@include xy-cell(4);
}
.events-content {
	@include xy-cell(8);
	h2 {
		margin-top: 0;
	}
}

.event-title {
	margin-bottom: rem-calc(15);
}
.event-date {
	font-weight: bold;
}