// FOOTER

footer {
	text-align: left;
	a {
		color: $beige;
		&:hover {
			color: $tint-beige;
		}
	}
}
.footer-main,
.footer-meta,
.footer-copyright {
	color: $white;
	h1,h2,h3,h4,h5,h6 {
		color: $tint-beige;
	}
	.fa-brands {
		font-size: 150%;
		padding: rem-calc(7);
	}
}

.footer-map-contact {
	@include xy-grid;
	@include flex-align(left, stretch);
}
.footer-map {
	background: $beige;
	flex: 0 0 auto;
	margin: 0;
	width: 100%;
	min-height: rem-calc(300);
	position: relative;
	overflow: hidden;
	@include flex;
	@include flex-align(center, stretch);
	@include breakpoint(large) {
		width: 40%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}	
	.map-button {
		position: absolute;
		z-index:3;
		bottom: rem-calc(30);
		right: rem-calc(60);
	}
}
.footer-contact {
	flex: 0 0 auto;
	width: 100%;
	margin: 0;
	background: $beige;
	@include breakpoint(large) {
		width: 60%;	
	}
	.footer-form {
		margin: rem-calc(30 0 30 30);
		max-width: rem-calc(810);
	}
}	

.footer-main {
	background: $light-green;
	color: $white;
	padding: rem-calc(30 0);
	@include breakpoint(large) {
		padding: rem-calc(60 0);
	}
	.inner {
		max-width: rem-calc(1400);
		margin: 0 auto;
		@include xy-grid;
		@include flex-align(justify, top);
	}
}
.footer-sitemap {
	@include xy-cell(12);
	@include xy-grid;		
	@include breakpoint(large) {
		@include xy-cell(9);
	}
	.header {
		@include xy-cell(12);
	}
	.sitemap {
		@include xy-cell(12);
	}
}
.sitemap ul {
	text-align: left;
	column-count: 2;
	@include breakpoint(medium) {
		column-count: 3;
		column-gap: rem-calc(30);		
	}
	@include breakpoint(large) {
		column-count: 3;		
	}
	@include breakpoint(xlarge) {
		column-count: 4;	
	}
	li {
		text-align: left;
		break-inside: avoid;
		line-height: 1.2;
		margin-bottom: rem-calc(15);
		ul {
			margin-top: rem-calc(15);
			margin-left: rem-calc(15);
			column-count:1;
			break-inside:avoid;
			ul {
				margin-top: rem-calc(15);
				margin-left: rem-calc(15);
			}
		}
	}	
	li.parent {
		font-weight: bold;
		li {
			font-weight: normal;
		}
	}
.children .children li:last-child {
	margin-bottom: 0;
}
	

}
.footer-newsletter {
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(3);		
	}
}
.footer-meta {
	background: $dark-green;
	padding: rem-calc(30 0);
	@include breakpoint(large) {
		padding: rem-calc(60 0);
	}
	.inner {
		max-width: rem-calc(1400);
		margin: 0 auto;
	}
	.logos {
		@include xy-cell(4);
		@include breakpoint(large) {
			@include xy-cell(2);
		}
	}
	.footer-text {
		@include xy-cell(8);
		@include breakpoint(large) {
			@include xy-cell(9);
			@include xy-cell-offset(1);
		}
	}
}
.footer-copyright {
	background: $darker-green;
	padding: rem-calc(30 0);
	p {
		font-size: 80%;
		margin: 0;
	}
	.icons {
		@include xy-cell(shrink);
		position:relative;
		list-style:none;
		display:flex;
		svg {
			width: 100%;
		}
		li {
			max-width: rem-calc(40);
		}
		li + li {
			margin-left: rem-calc(30);
		}
	}
	.text {
		@include xy-cell(auto);
	}
	a {
		text-decoration: underline;
	}
}

