.download-image {
	@include xy-cell(4);
    display: flex;
	@include flex-align(right, middle);
	img {
		display: block;
		max-width: rem-calc(200);
		-webkit-box-shadow: 0 0 10px 0 rgba($black,.5);
		box-shadow: 0 0 10px 0 rgba($black,.5);
	}
}
.download-content {
	@include xy-cell(8);
	@include flex-align-self(middle);
}