.image-copy {
	padding: rem-calc(30);
	@include breakpoint(large) {
		padding: rem-calc(60);	
	}
}
.image-copy.background-white + .image-copy.background-white {
	padding-top: 0;
}
.background-beigeDark {
	background: $beige;
}
.background-beigeLight {
	background: $tint-beige;
}
.inner {
	max-width: rem-calc(1400);
	margin: 0 auto;
	@include xy-grid;
}
.image-copy.compact .inner { 
	max-width: rem-calc(1030);
}
.block-image {
	margin-bottom: rem-calc(30);
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(5);	
	}
	img {
		border-radius: $global-radius;
	}
}
.block-content {
	@include xy-cell(12);
	@include breakpoint(large) {
		@include xy-cell(7);	
		@include flex-align-self(middle);
	}
}
.image-right .block-image {
	@include breakpoint(large) {
		@include flex-order(2);	
	}
}
.image-right .block-content {
	@include breakpoint(large) {
		@include flex-order(1);
	}
}