.testimonial blockquote {
	display: block;
	border-radius: $global-radius;
	padding: rem-calc(15 15 15 20);
	margin-inline: auto;
	margin-bottom: rem-calc(30);
	border: 1px solid rgba($dark-gray,.3);
	border-left: 8px solid $primary-color;
	p {
		font-size: rem-calc(24);
		line-height: 1.1;
	}
	cite {
		font-size: rem-calc(18);
		margin-bottom:0;
	}
}